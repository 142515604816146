const SurveyFormStart = {
    id: 'surveyFormStart',
    selector: '.js-survey-form-start',
    regionLists: [],
    clinicLists: [],
    doctorLists: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (container) {
        ['region', 'clinic'].forEach(function (key) {
            const list = container.querySelector(`.${key}`);
            if (list) {
                SurveyFormStart[`${key}Lists`].push(list);
                list.addEventListener('change', SurveyFormStart[`${key}Changed`]);
            }
        });
    },
    regionChanged: function (event) {
        const region = event.target;
        const clinic = region.closest(SurveyFormStart.selector).querySelector('.clinic');
        if (clinic) {
            const url = new URL(window.origin + clinic.dataset.url);
            url.searchParams.append('filters[region_id]', region.value);
            fetch(url)
                .then(r => r.json())
                .then(r => SurveyFormStart.updateClinicList(clinic, r.data));
        }
    },
    updateClinicList: function (container, data) {
        container.querySelectorAll('option').forEach(function (option) {
            if (String(option.value) !== '') {
                option.remove();
            }
        });
        data.forEach(function (item) {
            const option = document.createElement('option');
            option.value = item.id;
            option.innerText = item.attributes['name'];
            container.append(option);
        });
    },
    clinicChanged: function (event) {
        const clinic = event.target;
        const doctor = clinic.closest(SurveyFormStart.selector).querySelector('.doctor');
        if (doctor) {
            const url = new URL(window.origin + doctor.dataset.url);
            url.searchParams.append('filters[clinic_id]', clinic.value);
            fetch(url)
                .then(r => r.json())
                .then(r => SurveyFormStart.updateDoctorList(doctor, r.data));
        }
    },
    updateDoctorList: function (container, data) {
        container.querySelectorAll('option').forEach(function (option) {
            if (String(option.value) !== '') {
                option.remove();
            }
        });
        data.forEach(function (item) {
            const option = document.createElement('option');
            option.value = item.id;
            option.innerText = `${item.meta['full_name']} (${item.meta['specialization']})`;
            container.append(option);
        });
    }
}

export default SurveyFormStart;
