const AppointmentStatus = {
    id: "appointmentStatus",
    selector: ".js-appointment-status",
    container: undefined,
    url: undefined,
    inputs: [],
    init: function () {
        this.container = document.querySelector(this.selector);
        if (this.container) {
            this.url = this.container.dataset.url;
            this.container.querySelectorAll("input").forEach(this.apply);
        }
    },
    apply: function (input) {
        AppointmentStatus.inputs.push(input);
        input.addEventListener("click", AppointmentStatus.handleClick);
    },
    handleClick: function (event) {
        const input = event.target;
        const request = Biovision.jsonAjaxRequest("put", AppointmentStatus.url);
        request.send(JSON.stringify({status: input.value}));
    }
}

export default AppointmentStatus;
