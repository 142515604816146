require("./components/complex_link_with_search")
require("./components/nosology_toggle")
require("./components/nosology/atc_group_list_with_search")
require("./components/nosology/symptom_list_with_search")
require("./components/pharmacology/group_selector")
require('./components/surveys/appointment_memo');
require('./components/surveys/appointment_referrals');
require("./components/surveys/appointment_status")
require("./components/surveys/create_appointment")
require("./components/surveys/next_visit")
require('./components/surveys/survey_attachments')
require("./components/surveys/survey_diseases")
require("./components/surveys/survey_form_start")
require('./components/surveys/survey_upload_attachment');

import AppointmentMemo from "./components/surveys/appointment_memo";
import AppointmentReferrals from "./components/surveys/appointment_referrals";
import AppointmentStatus from "./components/surveys/appointment_status";
import AtcGroupListWithSearch from "./components/nosology/atc_group_list_with_search";
import ComplexLinkWithSearch from "./components/complex_link_with_search";
import CreateAppointment from "./components/surveys/create_appointment";
import GroupSelector from "./components/pharmacology/group_selector";
import NextVisit from "./components/surveys/next_visit";
import NosologyToggle from "./components/nosology_toggle";
import SurveyAttachments from "./components/surveys/survey_attachments";
import SurveyDiseases from "./components/surveys/survey_diseases";
import SurveyFormStart from "./components/surveys/survey_form_start";
import SurveyUploadAttachment from "./components/surveys/survey_upload_attachment";
import SymptomListWithSearch from "./components/nosology/symptom_list_with_search";

Biovision.addComponent(AppointmentMemo);
Biovision.addComponent(AppointmentReferrals);
Biovision.addComponent(AppointmentStatus);
Biovision.addComponent(AtcGroupListWithSearch);
Biovision.addComponent(ComplexLinkWithSearch);
Biovision.addComponent(CreateAppointment);
Biovision.addComponent(GroupSelector);
Biovision.addComponent(NextVisit);
Biovision.addComponent(NosologyToggle);
Biovision.addComponent(SurveyAttachments);
Biovision.addComponent(SurveyDiseases);
Biovision.addComponent(SurveyFormStart);
Biovision.addComponent(SurveyUploadAttachment);
Biovision.addComponent(SymptomListWithSearch);
