const SurveyUploadAttachment = {
    id: 'surveyUploadAttachment',
    selector: '.js-survey-upload-attachment',
    forms: [],
    init: function () {
        document.querySelectorAll(`${this.selector} form`).forEach(this.apply);
    },
    apply: function (element) {
        SurveyUploadAttachment.forms.push(element);
        element.addEventListener('submit', SurveyUploadAttachment.handleSubmit);
    },
    handleSubmit: function (event) {
        event.preventDefault();
        const form = event.target;
        const url = form.action;
        const data = new FormData(form);
        const request = Biovision.newAjaxRequest('post', url, function () {
            const response = JSON.parse(this.responseText);
            if (response.hasOwnProperty('data')) {
                const data = response.data;
                const list = form.closest(SurveyUploadAttachment.selector).querySelector('ul');
                const li = document.createElement('li');
                const file = document.createElement('div');
                file.classList.add('file');
                const anchor = document.createElement('a');
                anchor.href = data.meta.attachment_url;
                anchor.innerHTML = data.meta.attachment_name;
                file.append(anchor);
                const span = document.createElement('span');
                span.innerHTML = `(${data.meta.attachment_size})`;
                file.append(span);
                li.append(file);
                const description = document.createElement('div');
                description.classList.add('description');
                description.innerHTML = data.attributes.description;
                li.append(description);
                list.append(li);
            }
            form.reset();
        });

        request.send(data);
    }
}

export default SurveyUploadAttachment;
