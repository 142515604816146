const AtcGroupListWithSearch = {
    id: "atcGroupListWithSearch",
    selector: ".js-atc-group-list-with-search",
    inputs: [],
    measureUnits: new Map(),
    init: function () {
        const container = document.querySelector(this.selector);
        if (container) {
            container.querySelectorAll(".search input").forEach(this.apply);
            container.querySelectorAll("datalist option").forEach(this.addMeasureUnit);
        }
    },
    apply: function (input) {
        AtcGroupListWithSearch.inputs.push(input);
        input.addEventListener("input", AtcGroupListWithSearch.handleChange);
    },
    addMeasureUnit: function (option) {
        const [id, code] = option.value.split("|");
        AtcGroupListWithSearch.measureUnits.set(id, code);
    },
    handleChange: function (event) {
        const input = event.target;
        if (input.value.length > 0) {
            const container = input.closest(AtcGroupListWithSearch.selector);
            const url = new URL(input.dataset.url);
            const pattern = container.dataset.pattern;

            url.searchParams.append("q", input.value);

            const results = container.querySelector(".results");

            fetch(url.href)
                .then(r => r.json())
                .then(data => AtcGroupListWithSearch.processResponse(data, results, pattern));
        }
    },
    processResponse: function (response, container, pattern) {
        if (response.hasOwnProperty("data")) {
            container.innerHTML = "";
            response.data.forEach(function (item) {
                const li = document.createElement("li");
                const url = String(pattern.replace(/__id__/, String(item.id)));
                AtcGroupListWithSearch.addFoundItem(li, item, url)
                container.append(li);
            });
        }
    },
    addFoundItem: function (container, item, url) {
        const button = document.createElement("button");
        button.type = "button";
        button.classList.add("button", "button-secondary");
        button.innerHTML = item.meta.text_for_link;
        button.dataset.id = item.id;
        button.dataset.url = url;
        button.addEventListener("click", AtcGroupListWithSearch.selectAtcGroup);
        container.append(button);
        const wrapper = document.createElement("div");
        wrapper.classList.add("parameter-wrapper");
        const fields = [
            {
                name: "frequency",
                placeholder: "УПЧ",
                attributes: {min: 0, max: 1, step: 0.001}
            },
            {
                name: "average_daily_dose",
                placeholder: "СДД",
                attributes: {min: 0}
            },
            {
                name: "average_course_dose",
                placeholder: "СКД",
                attributes: {min: 0}
            },
        ];
        this.measureUnitRadio(wrapper, item.id);
        fields.forEach(f => wrapper.append(this.field(f)))
        container.append(wrapper);
    },
    measureUnitRadio: function (container, itemId) {
        this.measureUnits.forEach((name, id) => {
            const label = document.createElement("label");
            const input = document.createElement("input");
            const span = document.createElement("span");
            span.innerHTML = name;
            input.type = "radio";
            input.value = id;
            input.name = `measure_unit[${itemId}]`
        });
    },
    field: function ({type = "number", name, placeholder, attributes = {}}) {
        const label = document.createElement("label");
        label.classList.add("floating-label");
        const input = document.createElement("input");
        input.classList.add("input-text")
        input.type = type;
        input.placeholder = placeholder;
        input.required = true;
        for (const [k, v] of Object.entries(attributes)) {
            input.setAttribute(k, v);
        }
        label.append(input);
        const span = document.createElement("span");
        span.innerHTML = placeholder;
        label.append(span);
        return label;
    },
    selectAtcGroup: function (event) {
        const button = event.target;
        const mainContainer = button.closest(AtcGroupListWithSearch.selector);
        const listContainer = mainContainer.querySelector(".list");
        const weight = button.parentNode.querySelector("input").value;

        const request = Biovision.jsonAjaxRequest("put", button.dataset.url, function () {
            let list = listContainer.querySelector(".list-of-entities");
            if (!list) {
                list = document.createElement("ul");
                list.classList.add("list-of-entities");
                listContainer.innerHTML = "";
                listContainer.append(list);
            }
            const li = document.createElement("li");
            li.dataset.id = button.dataset.id;
            const dataDiv = document.createElement("div");
            dataDiv.classList.add("data");
            const div = document.createElement("div");
            div.innerHTML = `${weight}% — ${button.innerHTML}`;
            dataDiv.append(div);
            li.append(dataDiv);
            list.append(li);
            button.parentNode.remove();
        });
        button.disabled = true;
        request.send(JSON.stringify({scalar_weight: weight}));
    }
}

export default AtcGroupListWithSearch;
