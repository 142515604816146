const GroupSelector = {
    id: 'groupSelector',
    selector: ".js-pharmacology__group-selector",
    blocks: [],
    buttons: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (block) {
        GroupSelector.blocks.push(block);
        const button = block.querySelector("button");
        GroupSelector.buttons.push(button);
        button.addEventListener("click", GroupSelector.handleClick);
    },
    handleClick: function (event) {
        const button = event.target;
        const container = button.closest(GroupSelector.selector);
        const url = container.dataset.url;
        fetch(url).then(r => r.json()).then(r => GroupSelector.processResponse(container, r))
    },
    processResponse: function (container, response) {
        const list = container.querySelector("ul");
        list.innerHTML = "";
        if (response.hasOwnProperty("data")) {
            response.data.forEach(item => GroupSelector.addChild(list, item));
        }
    },
    addChild: function (list, item) {
        const li = document.createElement("li");
        const expandButton = document.createElement("button");
        const selectButton = document.createElement("button");
        expandButton.type = "button";
        expandButton.classList.add("expand", "button", "button-ghost");
        expandButton.dataset.url = item.links.self;
        expandButton.disabled = item.attributes.children_cache.length < 1;
        expandButton.innerHTML = "+";
        expandButton.addEventListener("click", GroupSelector.loadChildren);
        li.append(expandButton);

        selectButton.type = "button";
        selectButton.innerHTML = item.attributes.name;
        selectButton.dataset.id = item.id;
        selectButton.classList.add("button", "button-secondary");
        selectButton.addEventListener("click", GroupSelector.selectGroup);
        li.append(selectButton);

        list.append(li);
    },
    selectGroup: function (event) {
        const button = event.target;
        const container = button.closest(GroupSelector.selector);
        const input = container.querySelector("input");
        const current = container.querySelector(".current");
        input.value = button.dataset.id;
        current.innerHTML = button.innerHTML;
    },
    loadChildren: function (event) {
        const button = event.target;
        const container = button.parentNode;
        const url = button.dataset.url;
        const list = document.createElement("ul");
        container.append(list);
        fetch(url).then(r => r.json()).then(r => GroupSelector.processChildren(list, r));
    },
    processChildren: function (list, response) {
        response.relationships.children.data.forEach(item => GroupSelector.addChild(list, item));
    }
}

export default GroupSelector;
