const NosologyToggle = {
    id: "nosologyToggle",
    inputs: [],
    init: function () {
        document.querySelectorAll(".survey__doctor .diseases input").forEach(NosologyToggle.apply);
    },
    apply: function (input) {
        NosologyToggle.inputs.push(input);
        input.addEventListener("click", NosologyToggle.handleClick);
    },
    handleClick: function (event) {
        const input = event.target;
        document.querySelectorAll(`.survey__doctor .${input.name}`).forEach(function (element) {
            if (input.checked) {
                element.classList.add("hidden");
            } else {
                element.classList.remove("hidden");
            }
        });
    }
}

export default NosologyToggle;
