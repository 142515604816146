const SymptomListWithSearch = {
    id: "symptomListWithSearch",
    selector: ".js-symptom-list-with-search",
    inputs: [],
    init: function () {
        document.querySelectorAll(`${this.selector} .search input`).forEach(this.apply);
    },
    apply: function (input) {
        SymptomListWithSearch.inputs.push(input);
        input.addEventListener("input", SymptomListWithSearch.handleChange);
    },
    handleChange: function (event) {
        const input = event.target;
        if (input.value.length > 0) {
            const container = input.closest(SymptomListWithSearch.selector);
            const url = new URL(input.dataset.url);
            const prefix = container.dataset.prefix;

            url.searchParams.append("q", input.value);

            const results = container.querySelector(".results");

            fetch(url.href).then(r => r.json()).then(data => SymptomListWithSearch.processResponse(data, results, prefix));
        }
    },
    processResponse: function (response, container, prefix) {
        if (response.hasOwnProperty("data")) {
            container.innerHTML = "";
            response.data.forEach(function (item) {
                const li = document.createElement("li");
                const input = document.createElement("input");
                input.classList.add("input-text")
                input.type = "number";
                input.min = "0";
                input.max = "100";
                input.placeholder = "%";
                input.maxlength = 3;
                li.append(input);
                li.append("% — ");
                const button = document.createElement("button");
                button.type = "button";
                button.classList.add("button", "button-secondary");
                button.innerHTML = item.meta.text_for_link;
                button.dataset.id = item.id;
                button.dataset.url = prefix + item.id;
                button.addEventListener("click", SymptomListWithSearch.selectSymptom);
                li.append(button);
                container.append(li);
            });
        }
    },
    selectSymptom: function (event) {
        const button = event.target;
        const mainContainer = button.closest(SymptomListWithSearch.selector);
        const listContainer = mainContainer.querySelector(".list");
        const weight = button.parentNode.querySelector("input").value;

        const request = Biovision.jsonAjaxRequest("put", button.dataset.url, function () {
            let list = listContainer.querySelector(".list-of-entities");
            if (!list) {
                list = document.createElement("ul");
                list.classList.add("list-of-entities");
                listContainer.innerHTML = "";
                listContainer.append(list);
            }
            const li = document.createElement("li");
            li.dataset.id = button.dataset.id;
            const dataDiv = document.createElement("div");
            dataDiv.classList.add("data");
            const div = document.createElement("div");
            div.innerHTML = `${weight}% — ${button.innerHTML}`;
            dataDiv.append(div);
            li.append(dataDiv);
            list.append(li);
            button.parentNode.remove();
        });
        button.disabled = true;
        request.send(JSON.stringify({scalar_weight: weight}));
    }
}

export default SymptomListWithSearch;
