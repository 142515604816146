const SurveyDiseases = {
    id: "surveyDiseases",
    selector: ".js-survey-diseases",
    containers: [],
    inputs: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (container) {
        SurveyDiseases.containers.push(container);
        container.querySelectorAll("input[type=search]").forEach(SurveyDiseases.addInput);
    },
    addInput: function (input) {
        SurveyDiseases.inputs.push(input);
        input.addEventListener("input", SurveyDiseases.handleChange);
    },
    handleChange: function (event) {
        const input = event.target;
        const container = input.closest(SurveyDiseases.selector);
        const searchUrl = new URL(container.dataset.searchUrl);
        const results = input.closest(".search").querySelector(".results");
        if (input.value.length > 0) {
            searchUrl.searchParams.append("q", input.value);

            fetch(searchUrl.href)
                .then(r => r.json())
                .then(r => SurveyDiseases.showResults(results, r));
        } else {
            results.innerHTML = "";
        }
    },
    showResults: function (container, response) {
        container.innerHTML = "";
        if (response.hasOwnProperty("data")) {
            response.data.forEach(function (item) {
                const li = document.createElement("li");
                li.innerHTML = item.meta.text_for_link;
                li.dataset.id = item.id;
                li.addEventListener("click", SurveyDiseases.handleClick);
                container.append(li);
            });
        }
    },
    handleClick: function (event) {
        const item = event.target;
        const container = item.closest(SurveyDiseases.selector);
        const linked = item.closest(".list").querySelector(".linked");
        const data = {
            type: item.parentNode.dataset.type,
            disease_id: item.dataset.id,
            preliminary: container.dataset.preliminary
        }
        const url = container.dataset.linkUrl;

        Biovision.jsonAjaxRequest("post", url).send(JSON.stringify(data));
        linked.append(item);
    }
}

export default SurveyDiseases;
