const AppointmentMemo = {
    id: 'appointmentMemo',
    selector: '.js-appointment-memo-button',
    buttons: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (button) {
        AppointmentMemo.buttons.push(button);
        button.addEventListener('click', AppointmentMemo.handleClick);
    },
    handleClick: function (event) {
        const button = event.target;
        const container = button.closest('article');
        const form = document.createElement('form');
        form.action = button.dataset.url;
        form.method = 'post';
        form.target = '_blank';
        form.classList.add('hidden');
        AppointmentMemo.addToken(form);
        AppointmentMemo.addTreatment(form, container.querySelector('.appointment-treatment'));
        AppointmentMemo.addMedications(form, container.querySelector('.appointment-medications'));
        button.parentNode.append(form);
        form.submit();
    },
    addToken: function (form) {
        const csrfToken = document.createElement('input');
        csrfToken.type = 'hidden';
        csrfToken.name = 'authenticity_token';
        csrfToken.value = Biovision.csrfToken;
        form.append(csrfToken);
    },
    addTreatment: function (form, container) {
        container.querySelectorAll('input:checked').forEach(function (input) {
            form.append(input.cloneNode());
        });
    },
    addMedications: function (form, container) {
        container.querySelectorAll('input:checked').forEach(function (input) {
            const li = input.closest('li');
            li.querySelectorAll('input').forEach(function (field) {
                form.append(field.cloneNode());
            });
        });
    }
}

export default AppointmentMemo;
