const ComplexLinkWithSearch = {
    id: "complexLinkWithSearch",
    selector: ".js-complex-link-with-search",
    forms: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (container) {
        const form = container.querySelector("form");
        if (form) {
            ComplexLinkWithSearch.forms.push(form);
            form.addEventListener("submit", ComplexLinkWithSearch.handleSubmit);
        }
    },
    handleSubmit: function (event) {
        event.preventDefault();
        const form = event.target;
        const fieldset = form.querySelector("fieldset");
        const url = form.action;
        const request = Biovision.newAjaxRequest("post", url, function () {
            const container = form.closest(ComplexLinkWithSearch.selector);
            const response = JSON.parse(this.responseText);
            if (response.hasOwnProperty("data")) {
                if (response.data.id) {
                    ComplexLinkWithSearch.addItem(container, response.data);
                }
            }

            if (fieldset) {
                fieldset.disabled = false;
                form.reset();
                form.querySelectorAll(".js-searchable-list .text").forEach(function (text) {
                    text.innerHTML = "";
                });
            }
        });
        request.send(new FormData(form));

        if (fieldset) {
            fieldset.disabled = true;
        }
    },
    addItem: function (container, data) {
        const listContainer = container.querySelector(".list");
        let list = listContainer.querySelector(".list-of-entities");
        if (!list) {
            list = document.createElement("ul");
            list.classList.add("list-of-entities");
            listContainer.innerHTML = "";
            listContainer.append(list);
        }
        const li = document.createElement("li");
        li.dataset.id = data.id;
        const dataDiv = document.createElement("div");
        dataDiv.classList.add("data");
        const div = document.createElement("div");
        div.innerHTML = data.meta.html;
        dataDiv.append(div);
        li.append(dataDiv);
        list.append(li);
    }
}

export default ComplexLinkWithSearch;
