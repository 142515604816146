const NextVisit = {
    id: "nextVisit",
    selector: ".js-next-visit",
    container: undefined,
    input: undefined,
    url: undefined,
    init: function () {
        this.container = document.querySelector(this.selector);
        if (this.container) {
            this.url = this.container.dataset.url;
            this.input = this.container.querySelector("input");
            this.input.addEventListener("change", this.handleChange);
        }
    },
    handleChange: function (event) {
        const input = event.target;
        if (input.checkValidity()) {
            const data = {next_visit: input.value}
            input.disabled = true;
            const request = Biovision.jsonAjaxRequest("put", NextVisit.url, function () {
                input.disabled = false;
            });
            request.send(JSON.stringify(data));
        }
    }
}

export default NextVisit;
