/**
 * Cloning fields for adding survey attachments (get /surveys/:id/attachments)
 *
 * @type {Object}
 */
const SurveyAttachments = {
    id: 'surveyAttachment',
    selector: '.js-survey-attachments',
    buttons: [],
    limit: 3,
    init: function () {
        document.querySelectorAll(`${this.selector} button`).forEach(this.apply);
    },
    apply: function (button) {
        SurveyAttachments.buttons.push(button);
        button.addEventListener('click', SurveyAttachments.handleClick);
    },
    handleClick: function (event) {
        const button = event.target;
        const list = button.closest(SurveyAttachments.selector).querySelector('ol');
        const listCount = list.querySelectorAll('li').length
        if (listCount <= SurveyAttachments.limit) {
            SurveyAttachments.cloneListItem(list.querySelector('li:last-of-type'));
            if (listCount === SurveyAttachments.limit) {
                button.parentNode.remove();
            }
        }
    },
    cloneListItem: function (item) {
        const list = item.parentNode;
        const newItem = item.cloneNode(true)
        newItem.querySelector('input[type=text]').value = '';
        list.append(newItem);
        newItem.querySelector('input[type=file]').focus();
    }
}

export default SurveyAttachments;
