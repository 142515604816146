const CreateAppointment = {
    id: "createAppointment",
    selector: ".js-create-appointment",
    button: undefined,
    init: function () {
        this.button = document.querySelector(this.selector);
        if (this.button) {
            this.button.addEventListener("click", CreateAppointment.handleClick);
        }
    },
    handleClick: function (event) {
        const button = event.target;
        const url = button.dataset.url;
        const request = Biovision.jsonAjaxRequest("post", url, CreateAppointment.processResponse);
        button.disabled = true;
        request.send();
    },
    processResponse: function() {
        const response = JSON.parse(this.responseText);
        if (response.hasOwnProperty("data")) {
            const data = response.data;
            const links = data.links;
            if (links.hasOwnProperty("self")) {
                document.location.href = links.self;
            }
        }
    }
}

export default CreateAppointment;
